<template>
  <div class="maintenance" v-if="!isProcessing">
    <div class="maintenance__text">
      <h2 class="maintenance__text__title">ただいまメンテナンス中です。</h2>
      <v-icon class="maintenance__text__icon">warning_amber</v-icon>
      <p class="maintenance__text__explain">申し訳ございませんが、メンテナンス終了までお待ちください。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'maintenance',
  mounted () {
    // メンテナンス中じゃない場合のアクセスは弾く
    if (!this.isMaintenance) {
      this.$router.push({ name: 'home' })
      return
    }
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} 最新のメンテナンス情報
     */
    maintenance () {
      return this.$store.getters['maintenances/maintenance']
    },
    /**
     * @return {Boolean} メンテナンス中かどうか
     */
    isMaintenance () {
      return this.maintenance && !this.maintenance.endAt
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";
@import "@/assets/sass/fontfamily.scss";

.maintenance {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  padding: $padding_width $padding_width;
  text-align: center;
  background-color: $yellow_color;
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 2rem;
    }
    &__icon {
      margin-top: 20px;
      font-size: 14rem;
      &.theme--light.v-icon {
        font-family: $material-outlined;
        color: $black_lighten_color;
      }
    }
    &__explain {
      margin-top: 20px;
      font-size: 1.6rem;
      text-align: left;
    }
  }
}
</style>
